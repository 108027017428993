import { Campaigns } from '@gonfalon/billing';
import {
  enableBillingOpenapiMigrationUI,
  enableSelfServeExperimentationKeysLimit,
  isEnableStripePromoCodesEnabled,
} from '@gonfalon/dogfood-flags';
import { postSubscriptionPreview, PostSubscriptionPreviewRequestBody } from '@gonfalon/openapi';
// eslint-disable-next-line no-restricted-imports
import { fromJS, List, Map, OrderedMap } from 'immutable';
import { normalize, schema } from 'normalizr';

import {
  billingIntervals,
  createInvoice,
  createLegacyPlan,
  createLegacySubscription,
  createLimitPlans,
  createPaymentCard,
  createPlanList,
  createSubscription,
  createSubscriptionUsage,
  Invoice,
  LegacyPlan,
  LegacySubscription,
  LimitPlans,
  PaymentCard,
  PlanList,
  planTypes,
  Subscription,
  SubscriptionChange,
  SubscriptionPreview,
  SubscriptionUsage,
} from 'utils/billingUtils';
import { convertMapToOrderedMap } from 'utils/collectionUtils';
import http, { jsonToImmutable, jsonToImmutableError } from 'utils/httpUtils';
import { ImmutableMap } from 'utils/immutableUtils';
import { addQueryParams } from 'utils/urlUtils';

export async function getLegacyPlans(): Promise<List<LegacyPlan>> {
  return http
    .get('/internal/plans')
    .then(jsonToImmutable)
    .then((data) => data.get('items').map(createLegacyPlan))
    .catch(jsonToImmutableError);
}

export async function getPlans(): Promise<PlanList> {
  return http.get('/internal/billingv2/plans').then(jsonToImmutable).then(createPlanList).catch(jsonToImmutableError);
}

export const extendTrialEndDate = async (): Promise<Subscription> =>
  http
    .patch('/internal/billingv2/account/subscription/extend-trial')
    .then(jsonToImmutable)
    .then(createSubscription)
    .catch(jsonToImmutableError);

export const createSubscriptionCampaign = async (campaign: Campaigns): Promise<Subscription> =>
  http
    .patch('/internal/billingv2/account/subscription/campaigns', {
      body: {
        op: 'add',
        value: campaign,
      },
    })
    .then(jsonToImmutable)
    .then(createSubscription)
    .catch(jsonToImmutableError);

export async function getLimitPlans(planType = planTypes.PROFESSIONAL): Promise<LimitPlans> {
  return http
    .get(`/internal/billingv2/plans/${planType}/limits`)
    .then(jsonToImmutable)
    .then((data) => createLimitPlans(data.get('plans')))
    .catch(jsonToImmutableError);
}

export async function getLegacySubscription(): Promise<LegacySubscription> {
  // NOTE: the app nav uses this api, but not all pages have it in context
  // so hardcode it for now.
  return http
    .get('/internal/account/subscription')
    .then(jsonToImmutable)
    .then(createLegacySubscription)
    .catch(jsonToImmutableError);
}

export async function getSubscription(): Promise<Subscription> {
  return http
    .get('/internal/billingv2/account/subscription')
    .then(jsonToImmutable)
    .then(createSubscription)
    .catch(jsonToImmutableError);
}

export async function getSubscriptionUsage(): Promise<SubscriptionUsage> {
  return http
    .get('/internal/billingv2/account/subscription/usage')
    .then(jsonToImmutable)
    .then((data) => createSubscriptionUsage(data.get('_usage')))
    .catch(jsonToImmutableError);
}

export async function getPaymentCard(): Promise<PaymentCard | null> {
  return (
    http
      .get('/internal/account/card')
      .then(jsonToImmutable)
      .then(createPaymentCard)
      // 404 indicates the account does not yet have a card
      .catch(async (resp) => (resp.status === 404 ? Promise.resolve(null) : jsonToImmutableError(resp)))
  );
}

export const postSetupIntent = async () => {
  const response = await http
    .post('/internal/billingv2/card-setup-intent', {
      body: '',
    })
    .then(jsonToImmutable, jsonToImmutableError);
  return response.get('clientSecret');
};

export const postAddedCard = async (paymentMethodId: string) =>
  http
    .post('/internal/billingv2/card-added', {
      body: { paymentMethodId },
    })
    .then(jsonToImmutable)
    .then(createPaymentCard)
    .catch(jsonToImmutableError);

export async function setPaymentCard(stripeToken: string): Promise<PaymentCard> {
  return http
    .put('/internal/account/card', {
      body: JSON.stringify({ stripeToken }),
    })
    .then(jsonToImmutable)
    .then(createPaymentCard)
    .catch(jsonToImmutableError);
}

const invoices = new schema.Entity('invoices', {}, { idAttribute: 'key' });

type NormalizedInvoices = ImmutableMap<{
  result: ImmutableMap<{
    items: List<string>;
  }>;
  entities: ImmutableMap<{
    invoices: Map<string, Invoice>;
  }>;
}>;

export async function getInvoices(): Promise<OrderedMap<string, Invoice>> {
  return http.get('/internal/account/invoices').then(
    async (res) =>
      res.json().then((data) => {
        const normalized: NormalizedInvoices = fromJS(normalize(data, { items: [invoices] }));
        return normalized.withMutations((map) => {
          map.updateIn(['entities', 'invoices'], (entities: Map<string, Invoice>) =>
            convertMapToOrderedMap(entities, normalized.getIn(['result', 'items'])),
          );
          map.updateIn(['entities', 'invoices'], (entities) => (entities ? entities.map(createInvoice) : OrderedMap()));
        });
      }),
    jsonToImmutableError,
  );
}

export async function subscribe({
  subscription,
  stripeToken,
  prorationTime,
  promoCode,
  enableSelfServeUBBWithAnnualCommits,
  useSelfServeFoundation2023PricingV1,
  metadata = {},
}: {
  subscription: SubscriptionChange;
  stripeToken?: string;
  prorationTime: number;
  promoCode?: string;
  enableSelfServeUBBWithAnnualCommits?: boolean;
  useSelfServeFoundation2023PricingV1?: boolean;
  metadata?: { isDeveloperSignup?: boolean };
}): Promise<Subscription> {
  const sendPromoCode = promoCode !== undefined && promoCode !== '' && isEnableStripePromoCodesEnabled();
  let url = '/internal/billingv2/account/subscription';

  const nonFoundation: {
    promo?: string;
    stripeToken?: string;
    prorationTime?: number;
  } = {
    ...subscription.toRep(),
    stripeToken,
    prorationTime,
  };

  const monthlyFoundationPlanBody = {
    planType: subscription.planType,
    billingInterval: subscription?.billingInterval,
    metadata,
  };

  const annualFoundationPlanLimits = () => {
    if (enableSelfServeExperimentationKeysLimit()) {
      return {
        Hosts: subscription?.limits.Hosts,
        MonthlyActiveUsers: subscription?.limits.MonthlyActiveUsers,
        ExperimentationKeys: subscription?.limits.ExperimentationKeys,
      };
    }
    if (!useSelfServeFoundation2023PricingV1) {
      return {
        Hosts: subscription?.limits.Hosts,
        MonthlyActiveUsers: subscription?.limits.MonthlyActiveUsers,
        ExperimentationMAU: subscription?.limits.ExperimentationMAU,
      };
    }
    return {
      Hosts: subscription?.limits.Hosts,
      MonthlyActiveUsers: subscription?.limits.MonthlyActiveUsers,
    };
  };

  const annualFoundationPlanBody = {
    ...monthlyFoundationPlanBody,
    billingProcess: subscription?.billingProcess,
    limits: annualFoundationPlanLimits(),
    prorationTime,
  };

  const foundationPlan =
    subscription?.planType === planTypes.FOUNDATION2023 &&
    subscription?.billingInterval === billingIntervals.YEARLY &&
    enableSelfServeUBBWithAnnualCommits
      ? annualFoundationPlanBody
      : monthlyFoundationPlanBody;

  const body = subscription?.planType === planTypes.FOUNDATION2023 ? foundationPlan : nonFoundation;

  if (sendPromoCode) {
    url = addQueryParams(url, { promo: promoCode });
  }

  return http
    .put(url, {
      body,
    })
    .then(jsonToImmutable)
    .then(createSubscription)
    .catch(jsonToImmutableError);
}

export async function deleteSubscription(): Promise<void> {
  return http.delete('/internal/account/subscription').catch(jsonToImmutableError);
}

export async function previewSubscriptionPrice(
  subscription: SubscriptionChange,
  promoCode?: string,
): Promise<SubscriptionPreview> {
  const sendPromoCode = promoCode !== undefined && promoCode !== '' && isEnableStripePromoCodesEnabled();

  if (enableBillingOpenapiMigrationUI()) {
    try {
      const body: PostSubscriptionPreviewRequestBody = subscription.toRep();
      const response = await postSubscriptionPreview({
        body,
        query: sendPromoCode ? { promo: promoCode } : undefined,
      });

      if (!response.data) {
        throw new Error('Invalid response from subscription preview');
      }

      return fromJS(response.data);
    } catch (error) {
      throw fromJS({
        status: error instanceof Error ? 500 : 400,
        message: error instanceof Error ? error.message : String(error),
      });
    }
  }

  let url = '/internal/billingv2/account/subscription';
  const body: object & { promo?: string } = {
    ...subscription.toRep(),
  };

  if (sendPromoCode) {
    url = addQueryParams(url, { promo: promoCode });
  }

  return http
    .report(url, {
      body,
    })
    .then(jsonToImmutable, jsonToImmutableError);
}
